// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `div#loader-ring {
  display: inline-block;
  height: 100%;
  min-height: 1rem;
  min-width: 1rem;
  position: relative;
  width: 100%;
}
div#loader-ring div {
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border: 2px solid #fff;
  border-color: #fff transparent transparent transparent;
  border-radius: 50%;
  box-sizing: border-box;
  display: block;
  max-width: 100%;
  max-height: 100%;
  position: absolute;
  height: 100vw;
  width: 100vw;
}
div#loader-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
div#loader-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
div#loader-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}`, "",{"version":3,"sources":["webpack://./src/components/loader-ring/loader-ring.scss"],"names":[],"mappings":"AAAA;EACI,qBAAA;EACA,YAAA;EACA,gBAAA;EACA,eAAA;EACA,kBAAA;EACA,WAAA;AACJ;AAEI;EACI,8DAAA;EACA,sBAAA;EACA,sDAAA;EACA,kBAAA;EACA,sBAAA;EACA,cAAA;EACA,eAAA;EACA,gBAAA;EACA,kBAAA;EACA,aAAA;EACA,YAAA;AAAR;AAGI;EACI,uBAAA;AADR;AAII;EACI,sBAAA;AAFR;AAKI;EACI,uBAAA;AAHR;AAMI;EACI;IACI,uBAAA;EAJV;EAOM;IACI,yBAAA;EALV;AACF","sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
