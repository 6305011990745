// Module
var code = `<template>
    <require from="./loader-ring.scss"></require>
    <div class="lds-ring" id="loader-ring">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
    </div>
</template>`;
// Exports
export default code;