// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/**
 * This is the new reference from the latest Figma design
 * Please find the color in figma, search if it isn't already 
 * here, if so use it, if not add it with clear name
 */
section#loading {
  display: flex;
  margin: auto;
}
section#loading div.loading {
  color: rgb(255, 255, 255);
  margin: auto;
}
section#loading div.loading i {
  margin: auto;
  font-size: 2em;
}`, "",{"version":3,"sources":["webpack://./src/colors.scss","webpack://./src/components/loading/loading.scss"],"names":[],"mappings":"AAAA;;;;EAAA;ACCA;EACE,aAAA;EACA,YAAA;AAKF;AAJI;EACI,yBDmBM;EClBN,YAAA;AAMR;AALQ;EACE,YAAA;EACA,cAAA;AAOV","sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
