// Module
var code = `<template>
    <require from="./loading.scss"></require>
    <section id="loading">
        <div class="loading">
            <i class="fas fa-spinner fa-pulse"></i>
        </div>
    </section>
</template>`;
// Exports
export default code;