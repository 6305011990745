// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:export {
  breakpoint-mobile: 30rem;
  mobile: 30rem;
}

/**
 * This is the new reference from the latest Figma design
 * Please find the color in figma, search if it isn't already 
 * here, if so use it, if not add it with clear name
 */
section#leftsidebar {
  border-right: 1px solid var(--stroke-dark-base, #2F3340);
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  max-width: 5.5rem;
  overflow: hidden;
  padding: 0rem;
  transition: max-width 0.3s ease-out;
  width: fit-content;
}
section#leftsidebar mybankabrand {
  margin-left: 1.85rem;
}
section#leftsidebar .leftside_min {
  max-width: 5.5rem;
}
section#leftsidebar .leftside_min ul {
  width: 5.5rem;
}
section#leftsidebar .leftside_max {
  max-width: 20rem;
}
section#leftsidebar .leftside_max ul {
  width: 20rem;
}
section#leftsidebar ul {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0rem;
  padding: 0rem;
  width: 20rem;
}
section#leftsidebar ul li {
  align-items: center;
  color: var(--text-icons-dark-base-second, #AFB3C0);
  cursor: pointer;
  display: flex;
  flex-direction: row;
  font-family: "Inter";
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.375rem;
  list-style: none;
  margin: 0rem;
  padding: 1rem 2rem;
}
section#leftsidebar ul li img {
  margin: 0rem 0.75rem 0rem 0rem;
  height: 1.7rem;
  transition: margin 0.3s ease-in-out;
  width: 1.7rem;
}
section#leftsidebar ul li span {
  display: flex;
  overflow: hidden;
  text-overflow: ellipsis;
  transition: opacity 0.3s ease-in-out;
  white-space: nowrap;
}
section#leftsidebar ul li.active {
  box-sizing: content-box;
  background: var(--background-dark-base-third, #1F2330);
  border-left: 0.33rem solid var(--background-light-primary-main, #0681B1);
  color: var(--text-icons-dark-base-main, #F9F9F9);
}
section#leftsidebar ul li.active img {
  margin-left: -0.33rem;
}
section#leftsidebar ul.bottom {
  margin-bottom: 1rem;
}
section#leftsidebar ul.bottom li img.profile {
  object-fit: cover;
  border-radius: 50%;
}
section#leftsidebar ul.bottom li.active img.profile {
  margin-left: -0.33rem !important;
}

.no_display {
  display: none !important;
}

@media only screen and (min-width: 65rem) {
  section#leftsidebar {
    max-width: 20rem;
  }
}
@media only screen and (min-width: 40rem) and (max-width: 65rem) {
  section#leftsidebar {
    max-width: 5.5rem;
  }
  section#leftsidebar mybankabrand a span {
    opacity: 0;
  }
  section#leftsidebar mybankabrand a img {
    margin-right: 2rem !important;
  }
  section#leftsidebar ul li img, section#leftsidebar ul li.active img {
    margin-right: 3rem;
  }
  section#leftsidebar ul li span, section#leftsidebar ul li.active span {
    opacity: 0;
  }
}
@media only screen and (max-width: 40rem) {
  section#leftsidebar {
    max-width: 0rem;
  }
  section#leftsidebar ul li span, section#leftsidebar ul li.active span {
    opacity: 0;
  }
}`, "",{"version":3,"sources":["webpack://./src/breakpoints.scss","webpack://./src/components/left-side-bar/left-side-bar.scss","webpack://./src/colors.scss"],"names":[],"mappings":"AA+CA;EAEQ,wBAxBa;EA2BjB,aA3BiB;ACtBrB;;ACHA;;;;EAAA;ADGA;EACE,wDAAA;EACA,aAAA;EACA,sBAAA;EACA,iBAAA;EACA,iBDQc;ECPd,gBAAA;EACA,aAAA;EACA,mCAAA;EACA,kBAAA;AAQF;AANE;EACE,oBAAA;AAQJ;AALE;EACE,iBDHY;ACUhB;AANI;EACE,aDLU;ACahB;AAJE;EACE,gBDNY;ACYhB;AALI;EACE,YDRU;ACehB;AAHE;EAEE,aAAA;EACA,sBAAA;EACA,uBAAA;EACA,YAAA;EACA,aAAA;EACA,YAAA;AAIJ;AAFI;EACE,mBAAA;EACA,kDAAA;EACA,eAAA;EACA,aAAA;EACA,mBAAA;EACA,oBC2DmB;ED1DnB,eAAA;EACA,gBAAA;EACA,qBAAA;EACA,gBAAA;EACA,YAAA;EACA,kBAAA;AAIN;AAFM;EACE,8BAAA;EACA,cAAA;EAEA,mCAAA;EACA,aAAA;AAGR;AADM;EACE,aAAA;EACA,gBAAA;EACA,uBAAA;EACA,oCAAA;EACA,mBAAA;AAGR;AACI;EACE,uBAAA;EACA,sDAAA;EACA,wEAAA;EACA,gDAAA;AACN;AACM;EACE,qBAAA;AACR;AAGE;EACE,mBAAA;AADJ;AAIM;EACE,iBAAA;EACA,kBAAA;AAFR;AAQM;EACE,gCAAA;AANR;;AAaA;EACE,wBAAA;AAVF;;AAaA;EACE;IACE,gBDxFY;EC8Ed;AACF;AAaA;EACE;IACE,iBDlGY;ECuFd;EAeM;IACE,UAAA;EAbR;EAeM;IACE,6BAAA;EAbR;EAoBM;IACE,kBAAA;EAlBR;EAoBM;IACE,UAAA;EAlBR;AACF;AAyBA;EACE;IACE,eAAA;EAvBF;EA4BM;IACE,UAAA;EA1BR;AACF","sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
