import { AuthService } from 'aurelia-auth';
import { EventAggregator } from 'aurelia-event-aggregator';
import { autoinject } from 'aurelia-framework';
import { NavigationInstruction, PipelineResult, Router, RouterEvent } from 'aurelia-router';
import { connectTo } from 'aurelia-store';
import { default as env } from "../../../config/environment.json";

  @connectTo()
  @autoinject()
  export class LeftSideBar {
    private navEvent;
    private currentInstruction;
    private toggleSize : boolean
    private welcome_title: string

    /**
     * 
     * @param ea EventAggregator to listen router change page event
     * @param router Navigation
     * @param authService Getting the user information
     */
    constructor(private ea: EventAggregator, private router: Router, private authService: AuthService) {
        this.welcome_title = env.welcome_title;
        this.navEvent = this.ea.subscribe(RouterEvent.Success, (event: { instruction: NavigationInstruction; result: PipelineResult }) => {
        // console.log("current route: " + JSON.stringify(event.instruction));
        // console.log(event.result)
        this.currentInstruction = event.instruction;
        this.toggleSize = false;
      });
    }

    toggle() {
      this.toggleSize = !this.toggleSize;
    }

    deactivate() {
      this.navEvent.dispose();
    }
  }
    