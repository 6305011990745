// Imports
var ___HTML_LOADER_IMPORT_0___ = new URL("/static/img/left-side-bar/home_white.svg", import.meta.url);
var ___HTML_LOADER_IMPORT_1___ = new URL("/static/img/left-side-bar/home.svg", import.meta.url);
var ___HTML_LOADER_IMPORT_2___ = new URL("/static/img/left-side-bar/wallets_white.svg", import.meta.url);
var ___HTML_LOADER_IMPORT_3___ = new URL("/static/img/left-side-bar/wallets.svg", import.meta.url);
var ___HTML_LOADER_IMPORT_4___ = new URL("/static/svg/trade_white.svg", import.meta.url);
var ___HTML_LOADER_IMPORT_5___ = new URL("/static/svg/trade.svg", import.meta.url);
var ___HTML_LOADER_IMPORT_6___ = new URL("/static/img/left-side-bar/contacts_white.svg", import.meta.url);
var ___HTML_LOADER_IMPORT_7___ = new URL("/static/img/left-side-bar/contacts.svg", import.meta.url);
var ___HTML_LOADER_IMPORT_8___ = new URL("/static/svg/components/left-side-bar/power_off_hover_0681b1.svg", import.meta.url);
var ___HTML_LOADER_IMPORT_9___ = new URL("/static/svg/components/left-side-bar/power_off_afb3c0.svg", import.meta.url);
// Module
var code = `<template>
  <require from="./left-side-bar.scss"></require>
  <require from="../mybankabrand/mybankabrand"></require>
  <section id="leftsidebar"
    class.bind="currentInstruction.config.auth && !state.me.isFirstTime && currentInstruction.config.navModel.isActive ? '' : 'no_display'">
    
    <!-- <div id="sizeToggle" click.trigger="toggle()" class="\${toggleSize ? 'leftside_min' : 'leftside_max'}"> -->
    <mybankabrand click.delegate="router.navigateToRoute('home')">></mybankabrand>
    <!-- </div> -->
    <ul>
      <li id="leftsidebar-home"
        class.bind="currentInstruction.config.route == 'home' ? 'active' : ''"
        click.delegate="router.navigateToRoute('home')">
        <img 
          src="${___HTML_LOADER_IMPORT_0___}"
          if.bind="currentInstruction.config.route === 'home'" 
          alt="">
        <img 
          src="${___HTML_LOADER_IMPORT_1___}"
          if.bind="currentInstruction.config.route !== 'home'" 
          alt="">
        <span t="components.footer-left.home"></span>
      </li>
      <li id="leftsidebar-choose_wallet"
      class.bind="(currentInstruction.config.route === 'choose_wallet')
        || (currentInstruction.config.route === 'profile/choose_type_account')
        || (currentInstruction.config.route.startsWith('bitcoin/'))
        || (currentInstruction.config.route.startsWith('bank/'))
        ? 'active' : ''"
      click.delegate="router.navigateToRoute('choose_wallet')">
      <img 
        src="${___HTML_LOADER_IMPORT_2___}"
        if.bind="currentInstruction.config.route === 'choose_wallet'"
        alt=""> 
      <img  
        src="${___HTML_LOADER_IMPORT_3___}"
        if.bind="currentInstruction.config.route !== 'choose_wallet'"
        alt="">
        <span t="home.wallets"></span>
      </li>
      <!-- <li  id="leftsidebar-assets"
        class.bind="currentInstruction.config.route === 'assets' ? 'active' : ''"
        click.delegate="router.navigateToRoute('assets_home')">
        <img 
          src="/static/img/left-side-bar/assets_white.svg"
          if.bind="currentInstruction.config.route === 'assets'"
          alt="">
        <img 
          src="/static/img/left-side-bar/assets.svg"
          if.bind="currentInstruction.config.route !== 'assets'"
          alt="">
        <span t="components.footer-left.assets"></span>
      </li> -->
      <li id="leftsidebar-payments"
        class.bind="currentInstruction.config.route == 'payments' ? 'active' : ''"
        click.delegate="router.navigateToRoute('payments')">
        <img
          src="${___HTML_LOADER_IMPORT_4___}"
          if.bind="currentInstruction.config.route === 'payments'"
          alt="">
        <img 
          src="${___HTML_LOADER_IMPORT_5___}"
          if.bind="currentInstruction.config.route !== 'payments'"
          alt="">
        <span t="payments.title"></span>
      </li>
      <li id="leftsidebar-contacts"
        class.bind="currentInstruction.config.route === 'contacts/:page' ? 'active' : ''"
        click.delegate="router.navigateToRoute('contacts', { page: 'mine'})">
        <img
          if.bind="currentInstruction.config.route == 'contacts/:page'"
          src="${___HTML_LOADER_IMPORT_6___}"
        />
        <img
          if.bind="currentInstruction.config.route != 'contacts/:page'"
          src="${___HTML_LOADER_IMPORT_7___}"
        />
        <span t="contacts.term"></span>
      </li>
      <!-- <li id="leftsidebar-notifications"
        class.bind="currentInstruction.config.route == 'notifications' ? 'active' : ''"
        click.delegate="router.navigateToRoute('notifications')">
        <img
          if.bind="currentInstruction.config.route == 'notifications'"
          src="/static/img/left-side-bar/bell_white.svg"
        />
        <img
          if.bind="currentInstruction.config.route != 'notifications'"
          src="/static/img/left-side-bar/bell.svg"
        />
        <span t="components.footer-left.notifications"></span>
      </li> -->
    </ul>
    <div class="flex-grow"></div>
    <ul class="bottom">
      <li id="leftsidebar-profile"
        class.bind="currentInstruction.config.route == 'profile' ? 'active' : ''"
        click.delegate="router.navigateToRoute('profile')">
        <img src.bind="state.me.urlPic" class="profile" onerror="this.onerror=null; this.src='/img/user-circle.svg';" />
        <span>\${state.me.displayName}</span>
      </li>
      <li id="leftsidebar-logout"
        click.delegate="authService.logout()">
        <img
          if.bind="currentInstruction.config.route == 'logout'"
          src="${___HTML_LOADER_IMPORT_8___}"
        />
        <img
          if.bind="currentInstruction.config.route != 'logout'"
          src="${___HTML_LOADER_IMPORT_9___}"
        />
        <span t="profile.profile9"></span>
      </li>
    </ul>
  </section>
</template>
`;
// Exports
export default code;